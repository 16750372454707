import React from 'react';
import { Routes, Route } from 'react-router-dom';

import Ana from './pages/KrcsideMain.js';
import Digital from './pages/KrcsideDigital.js';
import End from './pages/KrcsideEndus.js';
import Indes from './pages/KrcsideInvest.js';


import './App.css';

function App() {
  return (
    <Routes>
      
      <Route path="" element={<Ana />} />
      <Route path="/digital" element={<Digital />} />
      <Route path="/industries" element={<End />} />
      <Route path="/invest" element={<Indes />} />


    </Routes>
  );
}

export default App;
